import React from 'react';

export default {
    title: "The Loops and Drops",
    date: "9/28/23",
    path: "7-the-loops-and-drops",
    contents: (
      <>
        <p>
          If you have followed this blog from the beginning, as I have, I think you'd agree it's been a roller
          coaster, but kind of a boring roller coaster with no loops or big drops, like this:

          <div className="image-container">
            <img className="img-medium" src="/squash/post_7/boring_coaster.png" alt="Boring roller coaster doodle"></img>
          </div>
          
          Frankly, and fortunately, there hasn't been a lot of bad news. There were some vines, sure, but those could be cut back. The
          jalapeños haven't thrived, which is a shame. But the squash seeds have sprouted, grown, and, in the case of
          the zucchini seeds, yielded zucchinis.
        </p>
        <p>
          But what events have gone unreported for the sake of this upbeat narrative? What villains have lurked
          in the soil or drifted in the air or sauntered through the yard, hitherto escaping description?
        </p>
        <p>
          Buckle up, for the underbelly of this story will now come to light, and it is both seedy and leafy.
        </p>
        <h3>Recall</h3>
        <p>
          Recall that, at their peak, the squash plants looked like this:

          <div className="image-container">
            <img className="img-large" src="/squash/post_4/three_boxes.jpg" alt="Healthy-looking squash plants"></img>
          </div>

          The leaves: so numerous, so green.
        </p>
        <h3>And now</h3>
        <p>
          Cut to this more recent picture:

          <div className="image-container">
            <img className="img-large" src="/squash/post_7/IMG_4875.jpg" alt="Unhealthy-looking zucchini plant"></img>
          </div>

          How can we understand what has happened here? In this case, by employing a protracted
          and dubious analogy; for those unfamiliar with the source material, I'll describe that first.
        </p>
        <h3>A brief summary of Theoden's arc in <i>The Two Towers</i></h3>
        <p>
          <i>The Lord of the Rings</i> is an award-winning film trilogy written and directed by J.R.R. Tolkien. Early in the second
          film, <i>The Two Towers</i>, four of our heroes (including the wizard Gandalf) come to the unpleasant realization that a supposed ally (King Theoden) has been possessed and corrupted by an
          evil wizard (Saruman the White) with inside help from smarmy associate Grima Wormtongue. While under Saruman's spell, Thoeden is sapped of his usual vigor and takes to dishing out petty
          zingers to insult his guests, in particular Gandalf, whom he dubs (in what we're led to believe is a devastating choice) "Gandalf Stormcrow". Not wanting this nickname to stick, Gandalf
          performs a kind of wizard exorcism, thereby asserting himself as a tough customer and restoring Theoden to something like his usual self. Theoden goes on to participate in multiple battles
          and earn middling marks for "Leadership | Troop Motivation" but high marks for "Cooperation | Not Being a Puppet of the Enemy".
        </p>
        <h3>The analogy</h3>
        <p>
          <ul>
            <li>Theoden: squash plants</li>
            <li>Grima Wormtongue: probably me</li>
            <li>Gandalf: hopefully me</li>
            <li>Saruman: powdery mildew?</li>
          </ul>
        </p>
        <h3>What is powdery mildew?</h3>
        <p>
          According to the <a href="https://www.almanac.com/pest/powdery-mildew">Farmer's Almanac</a> and the <a href="https://www.rhs.org.uk/disease/powdery-mildews">Royal Horticultural Society</a>,
          powdery mildew is a fungal disease that likes squash. It can cause leaf damage, manifesting as white, spreading patches
          and eventually turning some leaves yellow. I strongly suspect this is the is what struck our squash crop, and
          I think I gave it inside help by planting the squash too closely together, creating a higher-humidity
          environment that helped the spores take hold.
        </p>
        <p>
          Two suggested approaches for exorcising powdery mildew are to trim affected leaves and to thin out the
          vegetation to decrease humidity. I took a crack at the former, which, due to the extent of the spread,
          basically accomplished the latter as well. After clipping the leaves -- and entire plants -- that had been affected by powdery
          mildew (or were otherwise not looking well), I was left with only one full zucchini plant:

          <div className="image-container">
            <img className="img-large" src="/squash/post_7/IMG_5117.jpg" alt="Thinned out zucchini box"></img>
          </div>

          This was a humbling development, but one that I hope was best for the zucchini box as a whole. This pruning happened a while ago now;
          I hope to post again soon with more recent updates!
        </p>
        <h3>References</h3>
        <p>
          <ul>
            <li><a href="https://www.almanac.com/pest/powdery-mildew">Farmer's Almanac</a></li>
            <li><a href="https://www.rhs.org.uk/disease/powdery-mildews">Royal Horticultural Society</a></li>
          </ul>
        </p>
      </>
    )
  }