import React from 'react';

export default {
    title: "First Zucchini Meal",
    date: "8/31/23",
    path: "6-first-zucchini-meal",
    contents: (
      <>
        <p>
          I am happy to report that the first zucchini has been sliced, cooked, and eaten! It was featured in a meal
          inspired by the bibimbap recipe in Meera Sodha's <a href="https://meerasodha.com/books/">East</a>, but with
          one or two of my own modifications, which were by no means improvements.
          <br /><br />
          [Scroll down to Jump to Recipe.]
          <br /><br />          
          Thankfully, modifications notwithstanding, the result was still quite tasty, which I think spoke to a couple things:
          <ol>
            <li>
              The original recipe was great and forgiving
            </li>
            <li>
              It was pretty cool eating a zucchini that was harvested ~20 feet away!
            </li>
          </ol>
        </p>
        <h3>Preparation, in pictures</h3>
        <p>
          Step 1: put zucchini on cutting board
          <div className="image-container">
            <img className="img-medium" src="/squash/post_6/IMG_4694.jpg" alt="Zucchini on cutting board"></img>
          </div>
          Step 2: halve lengthwise
          <div className="image-container">
            <img className="img-medium" src="/squash/post_6/IMG_4695.jpg" alt="Zucchini on cutting board, sliced in half"></img>
          </div>
          Step 3: halve lenghtwise again, and cut into little chunks
          <div className="image-container">
            <img className="img-medium" src="/squash/post_6/IMG_4696.jpg" alt="Chopped zucchini on baking tray"></img>
          </div>
          Step 4: you're done
          <div className="image-container">
            <img className="img-medium" src="/squash/post_6/IMG_4698.jpg" alt="Final dish"></img>
          </div>
        </p>
        <h3>Modified recipe, in words</h3>
        <p>
          <ol>
            <li>Roast some zucchini and other veggies</li>
            <li>Make some rice</li>
            <li>Follow the gochujang sauce part of the recipe fairly closely</li>
            <li>Scramble, don't fry, a couple eggs. (I like to surprise people with my cooking; if you are feeling adventuresome, this choice is sure to elicit reactions like: "Why?")</li>
            <li>Combine</li>
            <li>Reflect that maybe you should have followed more parts of the recipe fairly closely</li>
            <li>Enjoy</li>
          </ol>
        </p>
        <h3>Epilogue</h3>
        <p>
          We have since used the <i>second</i> harvested zucchini in a similar dish, but this time with fried eggs!
        </p>
      </>
    )
  }