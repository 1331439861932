import React from 'react';

export default {
    title: "No Sprout About It",
    date: "8/1/23",
    path: "4-no-sprout-about-it",
    contents: (
      <>
        <p>
          "We're back and bigger than ever!" - squash plants
        </p>
        <h3>The bad</h3>
        <p>
          First, the bad news: all but one of our jalapeño spouts has disappeared. I'd like to think
          the jalapeño hivemind rethought having multiple sprouts and is redirecting its efforts into
          a single super-sprout. I will be keeping tabs on the holdout. The holdsprout. (Despite the title
          of this post, this one is very much still in the sprout phase.)
        </p>
        <h3>The good</h3>
        <p>
          Next, the good news: the squash plants are exhibiting real growth. Take a look:

          <div className="image-container">
            <img className="img-medium" src="/squash/post_4/three_boxes.jpg" alt="Raised beds with growing squash plants"></img>
          </div>

          <div className="image-container">
            <img className="img-medium" src="/squash/post_4/zucchini2.jpg" alt="Close up of zucchini box"></img>
          </div>

          If you're finding the relevant leaves and stems difficult to pick out, I don't blame you; the images are
          basically green rectangles. But somewhere in there are some squash plants, screaming out of the soil towards
          the sun, and sometimes off to the side. The zucchinis are now about yay high [imagine a gesture of two hands
          about 1-2 feet apart]. You can barely see the jalapeño pot because the butternut leaves are covering it.
          Maybe that's why the jalapeños aren't doing so well...?
        </p>
        <h3>The ugly</h3>
        <p>
          The images above are fairly uniformly green in part because the fence behind the raised beds is covered in vines. These greedy
          suckers are not satisfied to dominate the chain link; I found them encroaching on both raised beds and fully
          wrapping up one of the zucchini stems!

          <div className="image-container">
            <img className="img-medium" src="/squash/post_4/vine_wrap.jpg" alt="Vine wrapped around zucchini stem"></img>
          </div>

          After disentangling, the zucchini leaf in question looked pretty rough:

          <div className="image-container">
            <img className="img-medium" src="/squash/post_4/leaf_post_vine.jpg" alt="Vine wrapped around zucchini stem"></img>
          </div>

          The culprits:

          <div className="image-container">
            <img className="img-medium" src="/squash/post_4/vine_closeup.jpg" alt="Vine wrapped around zucchini stem"></img>
          </div>

          Since encroachment carries a five-yard penalty, the severed vine was duly dropped off at our neighbor's down the block.
        </p>
        <h3>Other notes and pictures</h3>
        <h4>Tree</h4>
        <p>
          An old, very large tree was removed from our backyard recently. One consequence is that the squash
          beds now get much more direct sun (and, probably, more rain) than they did previously.
          The squash leaves are starting to look a little holey -- not sure if it's from baking in the sun, from bugs, or from something else,
          but I'll be keeping an eye on that.
        </p>
        <h4>Fire pit</h4>
        <p>
          Our metal fire pit seems to think it, too, is a raised bed. Maybe next year we can plant and, come harvest, roast
          some squash in there:

          <div className="image-container">
            <img className="img-medium" src="/squash/post_4/firepit.jpg" alt="Vine wrapped around zucchini stem"></img>
          </div>
        </p>
        <hr />
        <p>
          Thanks for reading!
        </p>
      </>
    )
  }