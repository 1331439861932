import home from './home';
import projects from './projects';
import tv from './tv';
import work from './work';
import about from './about';

export default {
  home,
  projects,
  tv,
  work,
  about
};