const face1 = '                          /--- //\\_         /\\\\/\\/\\-\\/\\-\\      -/\\M\\\\/\\ \\\\MM-\\   ///          ||\\|   //            \\\\|   ||/==>   <==\\ |||   || <a>   <a>   |/)  \\|      |     /|/    |    / \\    /||     |  _ ^-^) _   |     \\\\( ______ )/ /      \\  \\____/ / /        \\         /          \\\\_____//      ';
const face2 = '                          /--- //\\_         /\\\\/\\/\\-\\/\\-\\      -/\\M\\\\/\\ \\\\MM-\\   ///          ||\\|   //       <==  \\\\|   ||/==>      \\ |||   || <a>   <a>   |/)  \\|      |     /|/    |    / \\    /||     |  _ ^-^) _   |     \\\\( ______ )/ /      \\  \\____/ / /        \\         /          \\\\_____//      ';
export const FACE = [face1, face2];

const dancing1 = '                                      ||                                              _||\\\\\\                                           \\_  _\\                         /--- //\\_           \\  \\                       /\\\\/\\/\\-\\/\\-\\          \\  \\                     -/\\M\\\\/\\ \\\\MM-\\          \\  \\                  ///          ||\\|           \\  \\                 //            \\\\|            \\  \\                ||/==>   <==\\ |||             \\  \\               || <a>   <a>   |/)            /  |               \\|      |     /|/            /   /                |    / \\    /||            /   /                 |  _ ^-^) _   |           /   /                  \\\\( ______ )/ |          /___/_                   \\  \\____/ /  /         /     |                    \\         //|        /      /                     \\\\______// |\\      /      |              _______/|          / \\____/      /              /        \\         /             /              /          \\_______/             /              /                                |              /                                /              /                                /              /      /|                        /              /______/ |                       |               |    /   |                       |               |   |    |                       |            ';
const dancing2 = '         ||                                            ///||_                                           /_  _/                                           /  /         /--- //\\_                          /  /        /\\\\/\\/\\-\\/\\-\\                       /  /        -/\\M\\\\/\\ \\\\MM-\\                     /  /       ///          ||\\|                    /  /        //            \\\\|                   /  /         ||/==>   <==\\ |||                   |  \\         || <a>   <a>   |/)                  \\   \\        \\|      |     /|/                    \\   \\        |    / \\    /||                      \\   \\       |  _ ^-^) _   |                      _\\___\\      \\\\( ______ )/ |                      |     \\      \\  \\____/ /  /                      \\      \\      \\         //|                       |      \\      \\\\______// |\\                       \\      \\____/|          / \\_______                \\           \\         /          \\                \\           \\_______/            \\                |                                \\                \\                                \\                \\                                \\                \\                        |\\      \\                |                       | \\______\\               |                       |   \\    |               |                       |    |   |   ';
export const DANCING = [dancing1, dancing2];

const vote = '\
 _____________________________________________________________________________  \
|  _________________________________________________________________________  | \
| |  ________    _           _________     _____      ________   _________  | | \
| | |  _____ \\  | |         |  _______|   / ___ \\    / _______| |  _______| | | \
| | | |     \\ \\ | |         | |          / /   \\ \\  | /         | |         | | \
| | | |     | | | |         | |_______  | |_____| | | |______   | |_______  | | \
| | | |_____/ / | |         |  _______| |  _____  |  \\______ \\  |  _______| | | \
| | |  ______/  | |         | |         | |     | |         \\ \\ | |         | | \
| | | |         | |         | |         | |     | |         | | | |         | | \
| | | |         | |_______  | |_______  | |     | |  _______/ | | |_______  | | \
| | |_|         |_________| |_________| |_|     |_| |________/  |_________| | | \
| |                                                                         | | \
| |  _                _    __________    ______________   ____________   _  | | \
| | \\ \\              / /  / ________ \\  |______   _____| |  __________| | | | | \
| |  \\ \\            / /  / /        \\ \\        | |       | |            | | | | \
| |   \\ \\          / /  / /          \\ \\       | |       | |__________  | | | | \
| |    \\ \\        / /  / /            \\ \\      | |       |  __________| | | | | \
| |     \\ \\      / /   | |            | |      | |       | |            | | | | \
| |      \\ \\    / /    \\ \\            / /      | |       | |            |_| | | \
| |       \\ \\  / /      \\ \\          / /       | |       | |                | | \
| |        \\ \\/ /        \\ \\________/ /        | |       | |__________   _  | | \
| |         \\__/          \\__________/         |_|       |____________| |_| | | \
| |_________________________________________________________________________| | \
|_____________________________________________________________________________| \
';

const register = '\
 _______________________________________________________________________________________________________ \
|  ___________________________________________________________________________________________________  |\
| |  ________    _________     ______    _________    ________   _________   _________   ________     | |\
| | |  _____ \\  |  _______|   / ____ \\  |___   ___|  / _______| |___   ___| |  _______| |  _____ \\    | |\
| | | |     \\ \\ | |          / /    \\_\\     | |     | /             | |     | |         | |     \\ \\   | |\
| | | |     | | | |_______  | |             | |     | |______       | |     | |_______  | |     | |   | |\
| | | |_____/ / |  _______| | |    ___      | |      \\______ \\      | |     |  _______| | |_____/ /   | |\
| | |  ___  _/  | |         | |   |_  |     | |             \\ \\     | |     | |         |  ___  _/    | |\
| | | |   \\ \\   | |         | |     | |     | |             | |     | |     | |         | |   \\ \\     | |\
| | | |    \\ \\  | |_______   \\ \\____/ /  ___| |___  ________/ |     | |     | |_______  | |    \\ \\    | |\
| | |_|     \\_\\ |_________|   \\______/  |_________| |________/      |_|     |_________| |_|     \\_\\   | |\
| |                                                                                                   | |\
| |                            _                _    __________    ______________   ____________   _  | |\
| |   _________     ______    \\ \\              / /  / ________ \\  |______   _____| |  __________| | | | |\
| |  |___   ___|   / ____ \\    \\ \\            / /  / /        \\ \\        | |       | |            | | | |\
| |      | |      / /    \\ \\    \\ \\          / /  / /          \\ \\       | |       | |__________  | | | |\
| |      | |     | |      | |    \\ \\        / /  / /            \\ \\      | |       |  __________| | | | |\
| |      | |     | |      | |     \\ \\      / /   | |            | |      | |       | |            | | | |\
| |      | |     | |      | |      \\ \\    / /    \\ \\            / /      | |       | |            |_| | |\
| |      | |     | |      | |       \\ \\  / /      \\ \\          / /       | |       | |                | |\
| |      | |      \\ \\____/ /         \\ \\/ /        \\ \\________/ /        | |       | |__________   _  | |\
| |      |_|       \\______/           \\__/          \\__________/         |_|       |____________| |_| | |\
| |___________________________________________________________________________________________________| |\
|_______________________________________________________________________________________________________|\
';

const registerVert = '\
 _________________________ \
|  _____________________  |\
| |  _                _ | |\
| | \\ \\              / /| |\
| |  \\ \\            / / | |\
| |   \\ \\          / /  | |\
| |    \\ \\        / /   | |\
| |     \\ \\      / /    | |\
| |      \\ \\    / /     | |\
| |       \\ \\  / /      | |\
| |        \\ \\/ /       | |\
| |         \\__/        | |\
| |     __________      | |\
| |    / ________ \\     | |\
| |   / /        \\ \\    | |\
| |  / /          \\ \\   | |\
| | / /            \\ \\  | |\
| | | |            | |  | |\
| | \\ \\            / /  | |\
| |  \\ \\          / /   | |\
| |   \\ \\________/ /    | |\
| |    \\__________/     | |\
| |  ______________     | |\
| | |______   _____|    | |\
| |        | |          | |\
| |        | |          | |\
| |        | |          | |\
| |        | |          | |\
| |        | |          | |\
| |        | |          | |\
| |        | |          | |\
| |        |_|          | |\
| |  ____________   _   | |\
| | |  __________| | |  | |\
| | | |            | |  | |\
| | | |__________  | |  | |\
| | |  __________| | |  | |\
| | | |            | |  | |\
| | | |            |_|  | |\
| | | |                 | |\
| | | |__________   _   | |\
| | |____________| |_|  | |\
| |_____________________| |\
|_________________________|\
';

export const VOTE = vote;
export const REGISTER = register;
export const REGISTER_VERT = registerVert;

