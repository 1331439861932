import React from 'react';

export default {
    title: "First Harvest",
    date: "8/16/23",
    path: "5-first-harvest",
    contents: (
      <>
        <p>
          Take a look at this:
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4643.jpg" alt="Zucchini barely visible behind leaves"></img>
          </div>
          An expert photograph of a leaf, right? Wrong! A terrible photograph of an actual zucchini (!) <i>behind</i> a leaf.
        </p>
        <p>
          That's right, our first zucchini has been harvested! And not a moment too soon, judging from the harvesting advice
          I've since read online. Nevertheless, here's a better picture of this first
          zucchini in situ:
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4650.jpg" alt="Zucchini in situ"></img>
          </div>
        </p>
        <p>
          For scale, here is the harvested zucchini next to some familiar foods and objects.
        </p>
        <p>
          Three stacked lemons (advantage: zucchini):
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4662.jpg" alt="Zucchini next to three lemons"></img>
          </div>
        </p>
        <p>
          Two stacked cans of sugar-free cola (ad: zuke):
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4664.jpg" alt="Zucchini next to two soda cans"></img>
          </div>
        </p>
        <p>
          A large canister of quick oats (ad: oats):
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4665.jpg" alt="Zucchini next to quick oats canister"></img>
          </div>
        </p>
        <p>
          For now, the zucchini sits on the counter. I hope a future post will recount its preparation into a spectacular dish.
        </p>
        <h3>Other developments</h3>
        <p>
          Some butternut squash fruits have started to grow recently as well:
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4653.jpg" alt="Butternut squash starting to grow #1"></img>
          </div>
          <div className="image-container">
            <img className="img-medium" src="/squash/post_5/IMG_4657.jpg" alt="Butternut squash starting to grow #2"></img>
          </div>
        </p>
        <p>
          The bottom squash looks a bit like one of those troll dolls, don't you think?
        </p>
      </>
    )
  }