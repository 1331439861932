import React from 'react';

export default {
    title: "Continued Sprout Growth",
    date: "6/30/23",
    path: "3-continued-sprout-growth",
    contents: (
      <>
        <p>
          Big news: we now have sprouts in all three pots / boxes! Nine of the 13 original squash seeds
          appear to have sprouted, along with a few of the jalapeño seeds. Some close-ups below.
        </p>
        <p>
          Zucchini:
          <div className="image-container">
            <img className="img-medium" src="/squash/zucchini_more_sprouts.jpg" alt="Image of zucchini sprouts"></img>
          </div>
        </p>
        <p>
          Jalapeños:
          <div className="image-container">
            <img className="img-medium" src="/squash/jalapeno_sprouts.jpg" alt="Image of jalapeño sprouts"></img>
          </div>
        </p>
        <p>
          Butternut squash:
          <div className="image-container">
            <img className="img-medium" src="/squash/butternut_sprouts.jpg" alt="Image of butternut sprouts"></img>
          </div>
        </p>
        <p>
          We've had some hard rains here in the past couple weeks. I wasn't sure how the team (i.e., the seeds)
          would handle it, but so far, so good, at least to my eyes.
        </p>
        <p>
          Thanks for tuning into this brief yet action-packed post, and please be sure to comment with any feedback!
        </p>
      </>
    )
  }