import React from 'react';

export default {
    title: "Harvest Recap",
    date: "11/22/23",
    path: "8-harvest-recap",
    contents: (
      <>
        <p>
          Morning frosts have hit southeastern Pennsylvania, so this post will likely be the last in the series. And since the previous
          post was nearly two months ago, there is much to cover!
        </p>
        <p>
          First, I'm happy to share that our remaining zucchini plant yielded two more zucchini!
            
          <div className="image-container">
            <img className="img-medium" src="/squash/post_8/IMG_5812.jpg" alt="Two harvested zucchinis on counter"></img>
          </div>

          This felt like a victory after the zucchini box's brush with powdery mildew and the aggressive pruning that followed.
        </p>
        <p>
          However, these zucchini didn't grow to nearly their predecessors' size (ink pen for scale):

          <div className="image-container">
            <img className="img-medium" src="/squash/post_8/IMG_5813.jpg" alt="Two harvested zucchinis next to ink pen for scale. The zucchinis are smaller"></img>
          </div>

          These may, in fact, be the smallest zucchini ever harvested.
        </p>
        <p>
          Next up, we have the butternut squash. I knew it was time to harvest the first two when I found that the vine supporting them had come detached
          from the rest of the plant, who knows how many days prior:

          <div className="image-container">
            <img className="img-large" src="/squash/post_8/severed_plant.jpeg" alt="Squash on severed vine in raised bed"></img>
          </div>

          All told, I think there were five butternut squashes in the harvest. Four are pictured here:

          <div className="image-container">
            <img className="img-large" src="/squash/post_8/IMG_5814.jpg" alt="Four butternut squash on counter, looking roughly the same size"></img>
          </div>

          They're not the biggest squashes ever harvested, but they did yield a good amount of food:

          <div className="image-container">
            <img className="img-medium" src="/squash/post_8/IMG_5820.jpg" alt="Squash halved on a cutting board"></img>
          </div>

          <div className="image-container">
            <img className="img-medium" src="/squash/post_8/IMG_5821.jpg" alt="Sliced butternut squash and zucchini on a baking sheet"></img>
          </div>

          (Pretty neat that these actually kind of look and behave like squash from the produce section, and they started as little seeds in some dirt! It makes you wonder - do all squash start as little seeds in some dirt?)
        </p>
        <p>
          I should come clean...the picture of the four squashes was staged:

          <div className="image-container">
            <img className="img-large" src="/squash/post_8/IMG_5815.jpg" alt="The four butternut squash from above; they are not the same size"></img>
          </div>

          and at least one was smaller than the ink pen:

          <div className="image-container">
            <img className="img-large" src="/squash/post_8/IMG_5818.jpg" alt="The four butternut squashes with an ink pen for size comparison"></img>
          </div>

          The important thing, though, is that &gt; 0 squashes were harvested!
        </p>
        <h3>Wrap-up</h3>
        <p>
          With the first squash-gardening season in the books, here are some final thoughts:
          <ul>
            <li>It was a heck of a ride</li>
            <li>You win some, you lose some</li>
            <li>There's always next season</li>
            <li>And a real one: this has been a very rewarding experience! Thanks for reading and for joining in the fun. I hope to try again next year. Happy gardening!</li>
          </ul>
        </p>
      </>
    )
  }